import axios from "axios";

const BASE_URI = process.env.REACT_APP_ASSET_API;

export const getEmotionsSongs = (emotion: string, uuid:string) => {

    return axios.post(`${BASE_URI}/api/v1/feeling`,
        {
            "feeling": emotion,
            "uuid": uuid
        },
    )
};