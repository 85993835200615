import CTAButton from "./CTAButton";

const Banner = () => {
    return (
        <div className="w-screen h-[40px] md:h-[60px] fixed top-0 left-0 flex justify-center items-center gap-2 md:gap-4 bg-[#FEC330] z-20">
            <div className="font-inter font-bold text-[11px] md:text-[24px] text-[#0E0F0C] text-center">
            Your mood playlist is on fire. But can you prove it? 
            </div>
            <CTAButton />
        </div>
    )
}

export default Banner;
