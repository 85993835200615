import React, { useState } from 'react'
import Headphones from '../../../../assets/headphones.svg'
import { ArrowRightIcon } from "@heroicons/react/20/solid";

export type userSentimentInputProps = {
  getSongs: (val?: string) => void,
  emotion: string,
  setEmotion: (emotion: string) => void,
}


const UserSentimentInput: React.FC<userSentimentInputProps> = ({ getSongs, emotion, setEmotion }) => {

  const emotions = ['happy', 'anxious', 'sad', 'loved', 'bored']

  const [checkLength, setCheckLength] = useState(false)

  const onClickEmotion = (emotionValue: string) => {
    setEmotion(emotionValue)
    getSongs(emotionValue)
  }


  return (
    <div className='w-full md:w-[40%] flex flex-col justify-evenly p-8 gap-14'>
      <div>
        <span className='text-3xl font-inter font-bold text-[rgba(239,209,101,1)]'>
          Enter your emotion,
        </span>
        <br />
        <span className='text-3xl font-inter font-bold text-[rgba(255,255,255,1)]'>
          Discover a soundtrack.
        </span>
        <a
        href="https://www.producthunt.com/posts/imfeeling?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-imfeeling"
        target="_blank"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=412299&theme=light"
          alt="ImFeeling - Your&#0032;emotion&#0044;&#0032;your&#0032;soundtrack | Product Hunt"
          style={{width:"200px",height: "54px", margin: "12px 0px 0px 0px"}}
          width="250"
          height="54"
        />
      </a>
      </div>
      <div className="flex flex-grow focus-within:z-10 items-stretch">
        <input
          className="block h-16 w-full rounded-none rounded-l-full border border-transparent bg-[rgba(255,255,255,0.05)] pl-6  focus:border-[rgba(239,209,101,1)] focus:outline-none text-base"
          placeholder="How are you feeling?"
          value={emotion} type="text"
          // className="shadow appearance-none rounded-none rounded-l-full w-full pl-10 h-16 bg-[rgba(255,255,255,0.05)] py-2  leading-tight focus:outline-none focus:shadow-outline" id="username" value={emotion} type="text" placeholder="Enter a Feeling or Select one below" 
          onKeyDown={(e) => {
            if (e.key === 'Enter' && emotion.length >= 3) {
              // Submit
              if (emotion.length >= 3)
                getSongs()
              else
                setCheckLength(true)
            }
          }}
          onChange={(event) => {
            let value = event.target.value
            value = value.replace(/[^a-zA-Z',.\s]+/g, '')
            setEmotion(value)
            setCheckLength(false)
          }} />
        <button
          type="button"
          className="relative -ml-px border-bg-[rgba(255,255,255,0.05)] inline-flex items-center space-x-2 rounded-r-full  bg-[rgba(255,255,255,0.05)] px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-black"
          onClick={() => { if (emotion.length >= 3) getSongs() }}
        >
          <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>


      {checkLength && <span className='text-red-500'>The maximum length of characters is 3</span>}

      <div className='flex flex-row w-full gap-4 flex-wrap'>

        {emotions.map((emotion, index) => {
          return (
            <React.Fragment key={index}>
              <button className="bg-[rgba(255,255,255,0.05)] border-2 border-[rgba(255,255,255,0.05)] text-[rgba(255,255,255,0.5)] rounded-lg py-2 px-4" onClick={() => onClickEmotion(emotion)}>
                {emotion}
              </button>
            </React.Fragment>
          )
        })}
      </div>
      <div className='hidden md:block pt-20'>
        {/* <img src={ScannerSvg} className='w-1/2 md:w-1/4' alt='scanner' />
         */}
        <a href="https://apps.apple.com/in/app/asset-your-music-stats/id6449702693" target='_blank' rel="noopener noreferrer">
          <img src={Headphones} className='' alt='scanner' />
        </a>
        <div className='text-left'>
          <span className='text-base font-inter italic'>
            View your all time
          </span>
          <br />
          <span className='text-base  font-inter text-yellow-300 italic'>
            Music Stats 🎵
          </span>
        </div>
      </div>
    </div>
  )
}

export default UserSentimentInput