import React from 'react'

export type songImageProps = {
  item: any,
  visible: boolean
}

const SongImage: React.FC<songImageProps> = ({ item, visible }) => {

  return (
    <div className='relative flex-shrink-0'>
      <img src={item.song_image || item.artist_image} alt={item.song_name} className={`transition delay-150 ease-in-out rounded-2xl w-48 h-48 md:h-96 md:w-96  ${visible ? '' : 'inset-10 bg-gradient-to-b from-transparent to-black opacity-60'}`} />
      <div className={`transition delay-150 ease-in-out ${visible ? "absolute inset-x-0 top-0 h-4 bg-gradient-to-t from-[rgba(8,8,8,0.1)] to-[rgba(13,22,40,1)] opacity-100 rounded-xl" : ''}`}></div>
    </div>
  )
}

export default SongImage
