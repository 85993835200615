import React from 'react'
import { ReactComponent as Twitter } from '../../../../assets/twitter.svg';
import { HiOutlineX } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";


import SongItem from '../SongItem'
import Lottie from 'lottie-react';
import music from '../../../../styles/chords.json'
export type songListProps = {
    songData: any
    loader: boolean
    alert: boolean
}

const SongList: React.FC<songListProps> = ({ songData, loader, alert }) => {
    const handleClick = () => {
        if ((window as any).toggleModal) {
            (window as any).toggleModal();
        }
    }

    return (
        <>
            {alert ? <div className='flex h-full md:w-[60%] w-full flex-col items-center justify-center md:px-0 flex-wrap px-1'>
                <span className='md:text-xl text-lg font-inter text-center  text-[rgba(255,255,255,1)]'>
                    If you or someone you know needs support,<br /> there is help. You are not alone.<br />
                </span>
                <br />
                <span className='md:text-xl text-lg font-inter text-center text-[rgba(255,255,255,1)]'>
                    Below you can find information and resources on how to get help. <br/>
                    Please consider visiting <a href='https://www.nimh.nih.gov/health/find-help/index.shtml' target='_blank' rel="noopener noreferrer"
                        className='font-bold text-[rgba(239,209,101,1)] underline'>Help Line</a> to speak with someone.
                </span>
               
                <div className='flex flex-row items-center justify-center pt-32'></div>
            </div>
                : songData?.length ?
                    <div className='md:w-[60%] md:pl-0 pl-5 pb-8 md:overflow-scroll h-full flex flex-wrap md:flex-nowrap md:flex-col gap-8'>
                        {songData.map((item: any, index: number) => <React.Fragment key={index}><SongItem item={item} /></React.Fragment>)}
                        <div className='flex flex-row items-center justify-center w-full md:pb-0 pb-8'>


                            {/* <button id="shareButton" aria-label="Share with Friends">
                    Share with Friends <span id="emojiWave">👋🏻</span>
                </button> */}

                        </div>
                    </div>
                    : <div className='flex h-full md:w-[60%] w-full pl-0 flex-col items-center justify-center md:px-0'>
                        <Lottie className=" md:w-96" animationData={music} loop={true} />
                        {loader &&
                            <span className='text-lg font-inter text-center text-[rgba(239,209,101,1)] pb-0'>
                                Feeling you 100%. Spinning up those jams now...
                            </span>
                        }
                        <div className='flex flex-row items-center justify-center pt-16'>
                            {/* <a href='https://twitter.com/AssetMoneyHQ' target='_blank' rel="noopener noreferrer">
                    <Twitter className='mr-4' />
                </a>
                <a
                    href="https://instagram.com/AssetMoneyHQ"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Instagram className='mr-4' />
                </a> */}
                            {/* <button id="shareButton" aria-label="Share with Friends">
                    Share with Friends <span id="emojiWave">👋🏻</span>
                </button> */}
                        </div>
                    </div>
            }

            <div id="shareModal">
                <div className="modal-content">
                    <button className="close-btn" onClick={handleClick}>
                        <HiOutlineX size={25} color='white' />
                    </button>
                    <h2>Share this page</h2>
                    <p>Choose a platform:</p>
                    <div className="flex flex-row justify-center my-8 mx-0">
                        <a id="twitterShare" className="share-link" target='_blank' rel="noopener noreferrer" href='#'
                        >
                            <Twitter className='mr-4' />
                        </a>
                        <a
                            id="whatappShare"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="share-link"
                            href='#'
                        >
                            <BsWhatsapp color='white' size={23} />
                        </a>

                    </div>
                    <p className='text-center'>Or copy the link below:</p>
                    <input type="text" id="shareUrl" value={window.location.href} className="share-input" />
                </div>
            </div>
            <button id="shareButton" aria-label="Share with Friends">
                Share with Friends <span id="emojiWave">👋🏻</span>
            </button>
        </>
    )
}

export default SongList