import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { generateUsername } from "friendly-username-generator";
import axios from "axios";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(config);

const auth = getAuth();

type loginProps = {
  setShowAuth: (showAuth: boolean) => void;
  user: User | null;
  setUser: (user: User | null) => void;
};

const AuthComponent: React.FC<loginProps> = ({
  setShowAuth,
  user,
  setUser,
}) => {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log(result.user);
      setShowAuth(false);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  const handleAppleSignIn = async () => {
    // You would implement Apple Sign-In here (requires additional setup)
    // Refer to Firebase documentation for Apple Sign-In setup.
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log(result.user);
      setShowAuth(false);
    } catch (error) {
      console.error("Apple Sign-In Error:", error);
    }
  };

  const createUser = async (username: string, accessToken: string) => {
    console.log("USERNAME FOR CREATE USER", username);
    // localStorage.setItem("askForUsername", true);
    //write a condition to ensure there is no user with same username
    const firebaseUser = getAuth().currentUser;
    if (firebaseUser !== null) {
      // const username = generateUsername(
      //   firebaseUser.displayName,
      //   firebaseUser.uid
      // );
      if (username && username !== null) {
        const response = axios
          .post(
            `https://users.asset.money/api/v1/user/profile`,
            {
              uuid: firebaseUser.uid,
              email: firebaseUser.email
                ? firebaseUser.email
                : "inboxdev.b@gmail.com",
              firstname: firebaseUser.displayName,
              // username: firebaseUser.displayName,
              username: username,
              status: "MN",
              profilepic: firebaseUser.photoURL,
              mobile:
                firebaseUser.phoneNumber !== null
                  ? parseInt(firebaseUser.phoneNumber)
                  : null,
            },
            {
              headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("USER CREATED", response);
          })
          .catch((error) => {
            console.log("ERROR CREATING USER", error);
          });
      }
    }
  };

  const getUser = async (accessToken: string) => {
    if (accessToken && accessToken.length > 0) {
      // setClaimState("claiming");
      try {
        const response = await axios.get(
          `https://users.asset.money/api/v1/user/profile`,
          {
            headers: {
              Authorization: accessToken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response && response.data) {
          // console.log('inside the resp')
          localStorage.setItem("loggedIn", "true");
        }
        // return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);

          if (error.response && error.response.status === 401) {
            // Call the createUser function here
            let username = localStorage.getItem("username");
            if (!username) {
              username = generateUsername();
              localStorage.setItem("username", username);
            }
            await createUser(username, accessToken);
          } else {
            console.log(error);
          }
        } else {
          console.log("no access token");
        }
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((accessToken) => {
          getUser(accessToken);
        });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {/* Add trigger button to open the overlay */}
      {/* {showLayout && ( */}
      <div className="overlay" onClick={() => setShowAuth(false)}>
        <div className="container">
          <div className="layout">
            <p
              className="text-base font-bold mt-11 text-black"
              // style={{ fontFamily: "Grandstander" }}
            >
              Login / Signup
            </p>
            <button className="transparent-button" onClick={handleGoogleSignIn}>
              <img
                className="w-6 h-6"
                src="/GoogleIcon.svg"
                alt="Google Icon"
              />
              <p className="text-black">Google</p>
            </button>
            <button className="transparent-button" onClick={handleAppleSignIn}>
              <img className="w-6 h-7" src="/AppleIcon.svg" alt="Apple Icon" />
              <p className="text-black">Apple</p>
            </button>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default AuthComponent;
