import { FaApple } from "react-icons/fa";

const CTAButton = () => {

    const handleCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        window.open("https://apps.apple.com/app/apple-store/id6449702693?pt=126399157&ct=IMF&mt=8", "_blank");
    }

    return (
        <button onClick={(e) => handleCTAClick(e)}>
            <div className="inline-block p-[2px] rounded-full shadow-[0_2px_4px_1px_rgba(14,15,12,0.3)] bg-gradient-to-br from-[#FFEBBA] to-[#98751D]">
                <div className="py-1 md:py-2 px-2 md:px-4 rounded-full bg-[#0E0F0C] flex flex-row justify-center items-center font-inter font-semibold text-[9px] md:text-[14px] leading-[120%] text-[#FEC330]">
                    <div>Download</div>
                    <div className="flex flex-col justify-center items-center pl-1">
                        <FaApple size={"1.5em"} />
                        <div className="h-[3px] md:h-[4px]"/>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default CTAButton;
