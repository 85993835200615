import React, { useEffect, useRef, useState } from 'react'
import SongImage from './SongImage'
import { SongMeta } from './SongMeta'

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.75
}

export type itemProps = {
  artist_image: string,
  artist_name: string,
  platform: string,
  song_image: string,
  song_name: string,
  song_url: string
}

export type songItemProps = {
  item: itemProps
}


const SongItem: React.FC<songItemProps> = ({ item }) => {
  const containerRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    setVisible(entry.isIntersecting);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    }
  }, [containerRef, options]);


  return (
    <div className='flex flex-row md:gap-16 sm:pl-5 w-full gap-3 items-center md:last:pb-0' ref={containerRef}><SongImage visible={visible} item={item} /><SongMeta visible={visible} item={item} /></div>
  )
}

export default SongItem
