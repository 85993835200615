import React from 'react'
import apple from '../../../../assets/apple.svg'
import youtube from '../../../../assets/you-tube.svg'
import spotify from '../../../../assets/spotify.svg'

export type songMetaProps = {
  visible: boolean
  item: any
}

export const SongMeta: React.FC<songMetaProps> = ({ visible, item }) => {
  return (
    // visible ?
    <div className='flex flex-col flex-wrap gap-2'>
      <p className='text-sm md:text-2xl px-3 font-bold'>
        {item.song_name}
      </p>

      <p className='text-xs md:text-lg px-3'>
        {item.artist_name}
      </p>

      <div className='flex flex-row '>

        {item.song_applemusic_url &&
          <a href={item.song_applemusic_url} target='_blank' rel='noreferrer'>
            <img src={apple} className='md:h-20 md:w-20 w-15 h-15' alt='apple' />
          </a>
        }
        {item.song_youtube_url &&
          <a href={item.song_youtube_url} target='_blank' rel='noreferrer'>
            <img src={youtube} className='md:h-20 md:w-20 w-15 h-15' alt='youtube' />
          </a>
        }
        {item.song_spotify_url &&
          <a href={item.song_spotify_url} target='_blank' rel='noreferrer'>
            <img src={spotify} className='md:h-20 md:w-20 w-15 h-15' alt='spotify' />
          </a>

        }
      </div>
    </div>
  )
}
